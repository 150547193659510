// Corporate cards
import CompleteVisibilityImg from "../../assets/media/images/cards/corporate-complete-visibility.png";
import SpendImg from "../../assets/media/images/cards/corporate-spend.png";
import UnlimitedCards from "../../assets/media/images/cards/corporate-unlimited.png";
import RetireOldImg from "../../assets/media/images/cards/corporate-retire.png";

// Testimonials
import FamasiImg from "../../assets/media/images/cards/testimonial-famasi.jpeg";
import FaajiImg from "../../assets/media/images/cards/testimonial-faaji.jpeg";
import PithAfricaImg from "../../assets/media/images/cards/testimonial-pith.jpeg";

export const HERO_VIDEO =
  "https://s3.eu-west-1.amazonaws.com/cms.trybrass.com/cards_hero_video_25808b9743.webm";

export const CORPORATE_CARDS_DATA = [
  {
    topic: "Complete visibility",
    text: "Get a full view of how every card holder spends within your organisation.",
    img: CompleteVisibilityImg,
  },
  {
    topic: "Spend within your limit",
    text: "Avoid unwanted surprises. Set spend limits on every virtual and physical card.",
    img: SpendImg,
  },
  {
    topic: "Unlimited cards",
    text: "Issue unlimited cards to as many teams or employees to spend for your company.",
    img: UnlimitedCards,
  },
  // {
  //   topic: "Retire the old",
  //   text: "Automate your company spend and say goodbye to expense reports that give no picture",
  //   img: RetireOldImg,
  // },
];

export const TESTIMONIALS = [
  {
    media: FaajiImg,
    quote: `Before choosing Brass, we had like other banks. For some reason, Brass just stood out cause first of all, we are operating a GenZ tech startup. I’ve been in the fintech space for a while. Their branding and their approach just seemed like the very right bank for what we were trying to build, their language and everything. It was so quick to onboard. In less than 10 minutes I was done.`,
    company: "Faaji",
  },
  {
    media: PithAfricaImg,
    quote: `I remember stumbling on Brass on Twitter and I was really intrigued cause one of our biggest problem as a startup was finding a bank that caters to us as their customers the way we cater to our customers as well. Cause one of the big things about us like I said is having that customer relationship and customer interaction.`,
    company: "Pith Africa",
  },
  {
    media: FamasiImg,
    quote: `The tenets of care are convenience, access and support for us and that's exactly what Brass does. It’s like a partnership that fits like a glove. When we were going to start, Brass allowed us conveniently create corporate accounts and sub accounts. They gave us access to all of the financial resources that we need and also assigned a key account manager to us to support us so whenever there are issues on the backend, Brass is always quick to solve them for us and continue to keep us going so we can continue to deliver that care to our customers.`,
    company: "Famasi",
  },
];

export const frontmatter = {
  name: "cards",
  faqContent: [
    {
      question: "What do I need to get a Brass corporate card?",
      answer:
        "To get a Brass corporate card, make sure your Brass account has at least N1,000 for a virtual card or N2,000 for a physical card. The delivery fee for a physical card depends on your location and will be displayed on your dashboard when you make a request.",
    },
    {
      question: "How do I report card complaints or disputes?",
      answer:
        "Log in to your Brass dashboard, go to Transactions and find the payment you’re interested in. Finally, select the menu button and choose Report Transaction. Our customer support team will be in touch to resolve.",
    },
    {
      question: "What happens if my card gets lost or stolen?",
      answer:
        "We advise you immediately deactivate the card from your Brass app. Open Cards > select the lost or stolen card > More, then choose “Deactivate card”.",
    },
    {
      question:
        "How long does it take for my physical card to arrive after I request for it?",
      answer:
        "Your card will arrive at your doorstep within 10-14 working days.",
    },
  ],
};
